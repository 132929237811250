import { useParams, useNavigate } from 'react-router-dom';
import { useCartStore } from '../store/cartStore';
import { products } from './Shop';

export default function ProductPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const addItem = useCartStore((state) => state.addItem);

  const product = products.find(p => p.id === id);

  if (!product) {
    return (
      <div className="min-h-screen bg-gray-50 pt-16 flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900 mb-4">Product Not Found</h1>
          <button
            onClick={() => navigate('/shop')}
            className="bg-amber-600 text-white px-6 py-2 rounded-md hover:bg-amber-700 transition-colors duration-300"
          >
            Return to Shop
          </button>
        </div>
      </div>
    );
  }

  const descriptionParts = product.description.split('⚠️');
  const mainDescription = descriptionParts[0].trim();
  const allergyWarning = descriptionParts[1] ? `⚠️${descriptionParts[1].trim()}` : '';

  const handleAddToCart = () => {
    addItem({
      id: product.id,
      name: product.name,
      price: product.price,
      image: product.image
    });
  };

  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Product Image */}
          <div className="rounded-lg overflow-hidden">
            <img
              src={product.image}
              alt={product.name}
              className="w-full h-full object-cover"
            />
          </div>

          {/* Product Details */}
          <div className="space-y-6">
            <h1 className="text-3xl font-bold text-gray-900">{product.name}</h1>
            
            <div className="text-2xl font-bold text-amber-800">
              ${product.price.toFixed(2)}
            </div>

            <div className="space-y-4">
              <p className="text-gray-600 text-lg leading-relaxed">
                {mainDescription}
              </p>
              
              {allergyWarning && (
                <p className="text-red-600 font-medium text-lg">
                  {allergyWarning}
                </p>
              )}
            </div>

            <button
              onClick={handleAddToCart}
              className="w-full md:w-auto bg-amber-600 text-white px-8 py-3 rounded-md hover:bg-amber-700 transition-colors duration-300 text-lg"
            >
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    </div>
  );
} 