import { useCartStore } from '../store/cartStore';
import { Link } from 'react-router-dom';

interface ProductCardProps {
  id: string;
  name: string;
  price: number;
  image: string;
  description: string;
}

export default function ProductCard({ id, name, price, image, description }: ProductCardProps) {
  const addItem = useCartStore((state) => state.addItem);

  const handleAddToCart = (e: React.MouseEvent) => {
    e.preventDefault();
    addItem({ id, name, price, image });
  };

  const descriptionParts = description.split('⚠️');
  const mainDescription = descriptionParts[0].trim();
  const allergyWarning = descriptionParts[1] ? `⚠️${descriptionParts[1].trim()}` : '';

  return (
    <Link to={`/product/${id}`} className="block h-full">
      <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300 h-full flex flex-col">
        <div className="relative h-64 overflow-hidden">
          <img
            src={image}
            alt={name}
            className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
          />
        </div>
        <div className="p-6 flex flex-col flex-grow">
          <h3 className="text-xl font-bold text-gray-800 mb-3">{name}</h3>
          
          <div className="flex-grow">
            {/* Main Description */}
            <p className="text-gray-600 mb-4 leading-relaxed">
              {mainDescription}
            </p>
            
            {/* Allergy Warning */}
            {allergyWarning && (
              <p className="text-red-600 font-medium mb-4 text-sm">
                {allergyWarning}
              </p>
            )}
          </div>

          <div className="mt-4 flex items-center justify-between">
            <span className="text-2xl font-bold text-amber-800">${price.toFixed(2)}</span>
            <button 
              onClick={handleAddToCart}
              className="bg-amber-600 text-white px-6 py-2 rounded-md hover:bg-amber-700 transition-colors duration-300"
            >
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    </Link>
  );
}