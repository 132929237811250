import React from 'react';
import ProductCard from '../components/ProductCard';

export const products = [
  {
    id: "original-qs",
    name: "Original Q's Jerky",
    price: 10.00,
    image: "https://images.unsplash.com/photo-1504674900247-0877df9cc836?auto=format&fit=crop&q=80&w=2070",
    description: `Made with USDA Choice beef, marinated in our signature soy sauce & oyster sauce blend. 
      Naturally preserved with organic honey - no nitrates added. 
      ⚠️ SHELLFISH ALLERGY WARNING: Contains oyster sauce.`
  },
  {
    id: "spicy-qs",
    name: "Spicy Q's Jerky",
    price: 10.00,
    image: "../public/spicy-single.jpg",
    description: `Made with USDA Choice beef, marinated in our signature soy sauce & oyster sauce blend, 
      with added chili pepper flakes for heat. Naturally preserved with organic honey - no nitrates added. 
      ⚠️ SHELLFISH ALLERGY WARNING: Contains oyster sauce.`
  }
];

export default function Shop() {
  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Our Products</h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Discover our selection of premium beef jerky, handcrafted with care using traditional methods
            and the finest ingredients.
          </p>
        </div>

        {/* Products Grid - Updated to 2 columns max */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-5xl mx-auto">
          {products.map((product) => (
            <ProductCard key={product.id} {...product} />
          ))}
        </div>
      </div>
    </div>
  );
}